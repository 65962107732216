import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { useLoginStore } from "../../utils/helpers"
import adapter from 'webrtc-adapter';
import {useStreamingOps} from "./provider_streaming";
import {useWebSocketComms} from "./provider_websocket_comms";

class VideoChannel {
  constructor(channel) {
    this.channel = new BroadcastChannel("classbucks")
    this.channel.onmessage = (e) => {
      console.log("message received", e.data)
    }
  }


}
export const RealtimeContext = React.createContext();

export const RealtimeContextProvider = observer(({ children }) => {
  const loginStore = useLoginStore()
  const [myid] = useState(+new Date()) //crypto.randomUUID())
  const [channel, setChannel] = useState(new BroadcastChannel("classbucks"))
  const {
    imServer, markServer, // common
    startCapture, stopCapture, serverStream, isServerStreaming, streamingId,  // server
    seekServerStream, clientStream, isClientStreaming // client
  } = useStreamingOps(myid, channel)

  const base = process.env.REACT_APP_USE_SAME_FE_ORIGIN === 'true' ?
      window.location.origin.replace('http', 'ws').replace('https', 'wss') :
      process.env.REACT_APP_WS_URL
  const wsurl = `${base}/room/${loginStore.id}/${loginStore.access_token}/`
  const {enableWebsocket, publish, subscribe, unsubscribe, addMessageListener, removeMessageListener, sendMeetingId} = useWebSocketComms(wsurl)


  const logProvider = false



  useEffect(() => {
    if (!loginStore.id || !loginStore.access_token || loginStore.teacher === null) {
      return;
    }


  }, [loginStore.id, loginStore.access_token, loginStore.teacher])


  // useEffect(() => {
  //   console.log('shared stream changed', sharedStream)
  // }, [sharedStream] );

  return (
    <RealtimeContext.Provider value={{
      imServer, markServer, // common
      startCapture, stopCapture, serverStream, isServerStreaming, streamingId,  // server
      seekServerStream, clientStream, isClientStreaming, // client

      enableWebsocket, publish, subscribe, unsubscribe, addMessageListener, removeMessageListener, sendMeetingId
    }}>
      {children}
    </RealtimeContext.Provider>
  );
})


// export const useCoinPairRepo = (coin) => {
//   const { repo } = React.useContext(RealtimeContext);
//   return repo[coin] || {};
// }
//
// export
