import React, {useEffect, useState} from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Card, Grid } from "@mui/material";
import math from "../../assets/images/math.png";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PaidIcon from "@mui/icons-material/Paid";
import { useLoginStore } from "services/helpers";
import book from '../../assets/icons/book-card.svg'
import {useApi} from "../../services/helpers";
import {Box} from "@mui/system";
import logo from "../../assets/images/logos/logo.png"
import actionIcons from "../../assets/images/background/action_icons.png"

const BORDER = "2px solid rgba(0, 0, 0, 0.15)";
const StudentHome = () => {
  const loginStore = useLoginStore();
  const api = useApi();
  const [courses, setCourses] = useState(null)

  const getStudentCourses = () => {
    api.getStudentCourses().handle({
      onSuccess: (res) => {
        setCourses(res.data)
      },
      errorMessage: "Error getting meetings",
    });
  }

  useEffect(() => {
    getStudentCourses()
  }, []);

  return (
    <>
      <MDBox display="flex" py={1}>
        <MDTypography
          fontSize={{xs:'24px', md:"30px"}}
          // borderBottom={BORDER}
          variant="h3"
          color="dark"
          fontWeight="regular"
        >
          Home
        </MDTypography>
      </MDBox>
      <Grid container>
        <Grid item container xs={12} sm={6} md={6} >
          <Grid
            item
            xs={12}
            sx={{borderRight:{xs:'none', sm:`${BORDER}`}}}
            py={2}
          >
            <MDTypography pl={1} mb={'15px'} fontSize={{xs:'18px', md:"20px"}} borderBottom={BORDER} color="primary">
              Total earnings by Subject
            </MDTypography>
            <Grid item container={'row'} xs={12} >

              <Grid item xs={4} display="flex" flexDirection="column" px={2} alignItems="center">
                {loginStore.student?.image?
                  <Box component={"img"} src={loginStore.student?.image} alt={"Profile Picture"} borderRadius={'50%'} width={"132px"}
                       maxHeight={"132px"} />
                  :
                  <AccountCircle sx={{ width:{xs:'80px', sm:'80vw'}, height:{xs:'80px', sm:'80vw'}, maxHeight:"100px" }} />
                }
                <MDTypography color="dark" fontSize={{xs:'14px', md:"20px"}}>{loginStore?.name}</MDTypography>

              </Grid>
                <Grid  item container  xs={8}>
                  <Grid item container display={'flex'} xs={12} px={1} >

                      {
                        courses?.length > 0 && courses.map(course =>
                        <>
                          <Grid item xs={8} wrap="nowrap" display={"flex"} alignItems={"center"}>
                            <Grid xs={7} display={'flex'} direction={'row'}>
                              <MDBox display={'flex'} alignItems={"center"}>
                                <img src={book} style={{minWidth:"22px"}} alt="location" />
                              </MDBox>
                              <MDTypography
                                color="dark"
                                style={{ textDecoration: "underline" }}
                                fontSize={{xs:'14px', md:"20px"}}
                                sx={{ whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}
                              >
                                {course?.name}
                              </MDTypography>
                            </Grid>
                          </Grid>
                          <Grid item display={'flex'}  justifyContent={'flex-end'} xs={4} direction={'row'} alignItems={"center"} >

                              <MDTypography
                                color="dark"
                                variant="body2"
                                fontSize={{xs:'14px', md:"20px"}}
                                sx={{ whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}
                              >
                                {course?.balance}
                              </MDTypography>
                              <PaidIcon color="primary" sx={{ ml: 1 }} />
                          </Grid>
                        </>
                        )
                      }

                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} sm={6} md={6} direction={'column'}>
          <Grid item xs={6} py={2}  >
            <MDTypography pl={1} mb={'15px'} borderBottom={BORDER} fontSize={{xs:'18px', md:"20px"}} color="primary" >Action Items</MDTypography>
            <Box component={"img"} src={actionIcons} alt={"actions"} sx={{ml:2}}/>
          </Grid>
        </Grid>

        <Grid item xs={12} py={1} borderBottom={BORDER}>
          <MDTypography color="primary" fontSize={{xs:'18px', md:"20px"}} pl={1}>Understanding & Engagement</MDTypography>
        </Grid>
        {
          courses?.length > 0 && courses.map(course =>
            <Grid item xs={12} my={1}>
              <ActivitiesCard course={course} />
            </Grid>
          )
        }
      </Grid>
    </>
  );
};
const TaskToDeliverCard = () => {
  return (
    <Card sx={{ "&:hover": { boxShadow: 4 } }}>
      <Grid container>
        <Grid
          item
          xs={2}
          sx={{ padding: 0, maxHeight: "80px" }}
          borderRight={(theme) => `6px solid ${theme.palette.primary.main}`}
        >
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={math}
            alt="image"
            style={{ width: "100%", height: "100%", borderRadius: "16px" }}
          />
        </Grid>
        <Grid xs={4} item display="flex" alignItems="center" px={2}>
          <MDTypography color="dark" style={{ textDecoration: "underline" }}>
            Class 1
          </MDTypography>
        </Grid>
        <Grid xs={6} item display="flex" justifyContent="flex-end" alignItems="center" pr={2}>
          <div style={{ borderRight: BORDER, paddingRight: "8px", marginRight: "8px" }}>
            <MDTypography color="dark" variant="body2">
              20 Aug.
            </MDTypography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <MDTypography color="dark" variant="body2">
              100
            </MDTypography>
            <PaidIcon color="primary" sx={{ ml: 1 }} />
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
const ActivitiesCard = ({course}) => {
  return (
    <Card sx={{ "&:hover": { boxShadow: 4 } }}>
      <Grid container>
        <Grid item
          xs={4}
          sm={2}
          sx={{ padding: 0, maxHeight: "80px" }}
          borderRight={(theme) => `6px solid ${theme.palette.primary.main}`}
        >
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={course?.image || logo}
            alt="image"
            style={{
              width: "100%",
              height: "100%",
              minHeight: "80px",
              borderRadius: "12px 0 0 12px",
              objectFit:course?.image? 'cover' : 'contain',
              opacity: course?.image? "none":"50%",
              padding: course?.image? "none":5,
          }}
          />
        </Grid>
        <Grid xs={8} sm={3} alignItems="center" px={2} alignSelf='center'>
          <MDTypography
          color="dark"
          style={{ textDecoration: "underline" }}
          fontSize={{xs:'16px', md:"20px"}}
          sx={{
            display: '-webkit-box',
            overflow:'hidden',
            whiteSpace: 'normal',
            textOverflow:'ellipsis',
            WebkitLineClamp:2,
            WebkitBoxOrient:'vertical',
            textAlign:'center'
          }}
          >
            {course?.name}
          </MDTypography>
        </Grid>
        <Grid
        borderTop={{xs:'2px solid rgba(0, 0, 0, 0.15) ', sm:'none'}}
        pY={{xs:1, sm:0}}
          xs={12}
          sm={7}
          item
          container
          wrap="nowrap"
          direction={'row'}
          justifyContent={{xs:"center", sm:'flex-end'}}
          alignItems="center"
          pr={1}
        >

          <Grid item xs={12}>
            <MDBox display={"flex"} alignItems={"center"} justifyContent={"end"} gap={1}>
              <MDTypography color="secondary" fontSize={{xs:'12px', md:"20px"}}  variant="body1">
                Understanding
              </MDTypography>
              <MDTypography
              fontSize={{xs:'12px', md:"20px"}}
                color="error"
                variant="body2"
                fontWeight={"bold"}
                pr={1}
                sx={{borderRight: "3px solid rgba(95, 99, 104, 0.50)"}}
              >
                50%
              </MDTypography>
              <MDTypography fontSize={{xs:'12px', md:"20px"}}  color="secondary" variant="body1">
                Engagement
              </MDTypography>
              <MDTypography
              fontSize={{xs:'12px', md:"20px"}}
                color="error"
                variant="body2"
                fontWeight={"bold"}
                pr={1}
              >
                76%
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
export default StudentHome;
