import { observer } from "mobx-react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import { RealtimeContext } from "../../services/streaming/provider";
import {Box, Grid, IconButton, Typography} from "@mui/material";
import attendance from "../../assets/images/attendance2.png";
import scan_me from "../../assets/images/scan_me.png";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { useApi } from "../../services/helpers";
import { EngagementQuestion } from "./engagement_question";
import MDButton from "../../components/MDButton";
import Draggable from "react-draggable";
import { useStores } from "models";
import { Resizable } from "re-resizable";
import MDBox from "../../components/MDBox";
import {BUTTON_OPTIONS} from "./button_options";
import QrCodeIcon from "@mui/icons-material/QrCode";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MeetingDisplayQRElement from "./meeting_display_qr";
import MeetingDisplayLeaderboard from "./meeting_display_leaderboard";
import MeetingDisplayToolbar from "./meeting_display_toolbar";
import {RewardNotification} from "./reward_notification";
import Lottie from 'react-lottie'
import AnimationData from 'assets/Lottie/AnimationConfetti.json'


const MeetingDisplay = observer(() => {
  const { meetingId } = useParams();
  const api = useApi();
  const { seekServerStream, clientStream, isClientStreaming } = React.useContext(RealtimeContext);
  const { enableWebsocket, subscribe, unsubscribe, addMessageListener, removeMessageListener } =
    React.useContext(RealtimeContext);
  const [loading, setLoading] = useState(true);
  const [meetingStatus, setMeetingStatus] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalStudentReward, setShowModalStudentReward] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const videoRef = React.useRef(null);
  const [latestQuestion, setLatestQuestion] = useState(null);
  const [rewardNotification, setRewardNotification] = useState(null);
  const [currentCode, setCurrentCode] = useState("not-available");
  const [updateCode, setUpdateCode] = useState(null);
  const asks_topic = `meeting/${meetingId}/asks`;
  const end_topic = `meeting/${meetingId}/end`;
  const start_topic = `meeting/${meetingId}/start`;
  const streaming_topic = `meeting/${meetingId}/streaming`;
  const ask_complete_topic = `meeting/${meetingId}/ask_complete`;
  const stats_topic = `meeting/${meetingId}/stats`;

  const random_call_topic = `meeting/${meetingId}/random_call_reward`;
  const [glowProps, setGlowProps] = useState({});

  const [latestQuestionAskedTs, setLatestQuestionAskedTs] = useState(null);
  const [updateGlow, setUpdateGlow] = useState(null);
  const parentRef = useRef(null);
  const timerRef = useRef(null);
  const GLOW_FOR_LAST_N_SECONDS = 10

  const [leaderboard, setLeaderboard] = useState([])
  const [statsUpdate, setStatsUpdate] = useState(+new Date())

  const getLeaderBoard = () => {
    api.meetingLeaderboard(meetingId, false).handle({
      onSuccess: (response) => { setLeaderboard(response.data); console.log(response.data) },
      errorMessage: "Error getting leaderboard info"
    })
  }

    const AnimationOptions = {
        loop: false,
        autoplay: true,
        animationData: AnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

  useEffect(() => {
    getLeaderBoard()
  }, [meetingId, statsUpdate])



  useEffect(() => {
    const listener = (message) => {
      if (message.type === "welcome") {
        subscribe(asks_topic);
        subscribe(end_topic);
        subscribe(start_topic);
        subscribe(streaming_topic);
        subscribe(ask_complete_topic);
        subscribe(stats_topic);
        subscribe(random_call_topic);
      } else if (message?.type === "message") {
        if (message?.topic === asks_topic) {
          const newq =  JSON.parse(message?.payload);
          setLatestQuestion(newq);
          setLatestQuestionAskedTs( + new Date());
          parentRef.current = newq.parent;
          setShowModal(true);
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          timerRef.current = setInterval(() => {
            setUpdateGlow(+new Date());
          }, 1000);
        } else if (message?.topic === end_topic || message?.topic === start_topic) {
          updateStatus();
        } else if (message?.topic === stats_topic) {
            setStatsUpdate(+new Date())
          console.log("stats updated")
        } else if (message?.topic === streaming_topic) {
          setStreaming(message?.payload === "true");
        } else if (message?.topic === ask_complete_topic) {
          const complete_parent_id = message?.payload
          if( complete_parent_id === parentRef.current){
            setShowModal(false);
            setLatestQuestion(null);
            setLatestQuestionAskedTs(null);
            parentRef.current = null;
            if (timerRef.current) {
              clearInterval(timerRef.current);
              timerRef.current = null
            }
          }
        }
        else if (message?.topic === random_call_topic) {
          setRewardNotification(JSON.parse(message?.payload));
          console.log("The message is:", message?.payload)
          setShowModalStudentReward(true);
        }

      }
    };
    addMessageListener(listener);
    enableWebsocket();
    return () => {
      removeMessageListener(listener);
    };
  }, [addMessageListener, asks_topic, enableWebsocket, removeMessageListener, subscribe, ]);

  useEffect(() => {
    updateStatus();
  }, []);

  useEffect(() => {
    const elapsed = latestQuestionAskedTs ? (+new Date() - latestQuestionAskedTs) / 1000 : 0;
    const timeLeft = (latestQuestion?.q_timeout || 0) - elapsed;
    updateGlowProps(latestQuestion, timeLeft < GLOW_FOR_LAST_N_SECONDS)
  }, [latestQuestion, updateGlow])

  const updateStatus = () => {
    api.getMeetingStatus(meetingId).handle({
      onSuccess: (res) => {
        setMeetingStatus(res.data);
      },
      errorMessage: "Error getting meeting status",
    });
  };

  useEffect(() => {
    if (meetingStatus && !meetingStatus.ended) {
      api.get_QR_info(meetingId).handle({
        onSuccess: (res) => {
          const { qrcode, seconds } = res.data;
          setCurrentCode(`${window.location.origin}/?ac=${qrcode}`);
          setTimeout(() => {
            setUpdateCode(+new Date());
          }, seconds * 1000);
        },
        errorMessage: "Error getting QR info",
      });
    }
  }, [api, meetingId, updateCode, meetingStatus]);

  useEffect(() => {
    if (clientStream !== null) {
      videoRef.current.srcObject = clientStream;
    }
  }, [loading, clientStream]);

  const updateGlowProps = (question, animate) => {
    const color = BUTTON_OPTIONS.find((b) => b.id === question?.random_button)?.color;
    if (!color){
      setGlowProps({})
      return
    }

    const glowSize = '100px'
    const glowBiggerSize = '50px'
    if(animate) {
      const ida = `cdisplay-${question?.parent}`
      setGlowProps({
        [`@keyframes ${ida}`]: {
          "0%": {
            boxShadow: `inset 0 0 ${glowSize} ${color}`,
          },
          "50%": {
            boxShadow: `inset 0 0 ${glowBiggerSize} ${color}`,
          },
          "100%": {
            boxShadow: `inset 0 0 ${glowSize} ${color}`,
          },
        },
        animatedElement: {
          animation: `$${ida} 1300ms infinite`,
        },
        animation: `${ida} 1300ms infinite`,
      })
    }else{
        setGlowProps({
            boxShadow: `inset 0 0 ${glowSize} ${color}`,
        })
    }
  }
  const a = {}

  return (
      <Box
        height="100vh"
        width="100%"
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        sx={{...glowProps}}
      >
        <Box
            alignItems={"center"}
            justifyContent={"center"}
            {...(isClientStreaming ? {height:"100vh", width:"100%"} : {})}
        >

          {!isClientStreaming ? (
            <>
              {meetingStatus && !meetingStatus?.ended && !streaming && (
                <Typography color="warning.main" fontWeight="bold">
                  Class will start soon...
                </Typography>
              )}
              {meetingStatus && !meetingStatus?.ended && streaming && (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography color="warning.main" fontWeight="bold" pb={2}>
                    Class ready to begin...
                  </Typography>
                  <MDButton
                    variant="contained"
                    onClick={() => {
                      seekServerStream();
                      //startMeeting();
                    }}
                    color={"primary"}
                    size={"large"}
                  >
                    Start screen sharing
                  </MDButton>
                </Box>
              )}

              {meetingStatus && meetingStatus?.ended && (
                <Typography color="warning.main" fontWeight="bold">
                  Class ended. Thank you!
                </Typography>
              )}
            </>
          ) : (
            <video ref={videoRef} autoPlay width="100%" height="99%" />
          )}
          {rewardNotification &&
            <RewardNotification
              showModal={showModalStudentReward}
              title={rewardNotification?.student}
              message={rewardNotification?.message}
              handleClose={() => {
                setShowModalStudentReward(false)
                setRewardNotification(null)
              }}
              sx={{position:'fixed'}}
            />
          }
        </Box>

      {(meetingStatus && !meetingStatus?.ended) && <>
        {/*<MeetingDisplayToolbar/>*/}
        <MeetingDisplayQRElement currentCode={currentCode}/>
        <MeetingDisplayLeaderboard leaderboard={leaderboard}/>
        </>}

        {showModalStudentReward &&
          <Lottie
            options={AnimationOptions}
            height={'100%'}
            width={'100%'}
            style={{position:'fixed'}}
          />
        }

      {latestQuestion && (
        <EngagementQuestion
          showModal={showModal}
          question={latestQuestion}
          handleClose={() => {
            setShowModal(false);
            setLatestQuestion(null);
            parentRef.current = null;
            if (timerRef.current) {
              clearInterval(timerRef.current);
              timerRef.current = null
            }
          }}
        />
      )}
    </Box>
  );
});

export default MeetingDisplay;
